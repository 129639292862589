
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import router from "@/router";
import { leadsData, ql } from '@/store/stateless/store';
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useUser, useRoleBasedConditions } from "@/store/composable/User";
import { useUpdateCustomerData } from '@/store/composable/Customer';
import { dashboardInsuranceType } from '@/store/composable/BreadCrumbs';
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    // Dropdown1,
    // AddCustomerModal
  },
  setup() {
    const route = useRoute()
    const store = useStore();
    const { dAgentDashboard, dMarketingDashboard, dAccountsDashboard } = useRoleBasedConditions()

    const getDashboardDateTitle = computed(() => {
      return store.getters.getDashboardDateTitle;
    });

    const { user_id } = useUser();
    
    const openWizard = (wizardUrl) => {
      window.open(wizardUrl + '?agent_id=' + user_id, "_blank");
    };

    const bread = (e) => {
      router.push({ name: link(e) });
    };
    const link = (e) => {
      switch (e) {
        case "Customer Details":
        case "Add Lead - Car & Driver Details":
        case "Add User Details":
        case "Generate Quote PDF":
        case "Make Invoice":
          return "customer-list"; //
        case "All Invoices":
        case "Amend Invoice":
          return "invoice-list"; //
        case "Invoices For Approval":
          return "policy-approval"; //
        case "Declined Invoices":
          return "declined-invoice"; //
        case "Policy Assigned":
        case "Policy Details":
          return "policy-assigned"; //
        case "Policy Completed":
          return "policy-completed"; //
        case "Policy Cancellation Request":
          return "policy-cancellation-request"; //
        case "Policies Cancelled":
          return "policy-cancelled"; //
        case "Users Listing":
        case "Add User":
        case "Edit User":
          return "user-list"; //
        default:
          return "dashboard";
      }
    };

    const travelWizard = ref(process.env.VUE_APP_TRAVEL_WIZARD_URL);

    const user = computed(() => {
      return store.getters.currentUser;
    });

    // const openQL = () => {
      
    // }

    const quickleadModal = ref()

    const setViewQuickLead = () => {
      // ql.isOpen = true
      store.commit(Mutations.SET_VIEW_QUICK_LEAD, true);
    };

    const miskInsuranceType = ref()
    async function miskInsuranceTypes(){
      let response = await useUpdateCustomerData({linkType: 'insurance-types-list', user_id: -1, active_only:true })
      miskInsuranceType.value = response.data.data.types
    }

    onMounted(async() => {
      await miskInsuranceTypes()
    });
    

    return {
      setViewQuickLead,
      openWizard,
      travelWizard,
      router,
      quickleadModal,
      getDashboardDateTitle,
      bread,
      toolbarWidthFluid,
      user,
      leadsData,
      ql,
      miskInsuranceType,
      miskInsuranceTypes,
      dashboardInsuranceType,
      dAgentDashboard,
      dMarketingDashboard,
      dAccountsDashboard,
      route
    };
  }
});
