const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Leads & Tasks",
        route: "/customers/list",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      // {
      //   heading: "Invoice",
      //   route: "/invoice/list",
      //   svgIcon: "media/icons/duotune/general/gen028.svg",
      //   fontIcon: "bi-layers",
      // }
    ],
  },
  {
    pages: [
      {
        sectionTitle: "Invoices",
        route: "/invoices",
        svgIcon: "media/icons/duotune/general/gen028.svg",
        fontIcon: "bi-layers",
        sub: [
                {
                  heading: "All Invoices",
                  route: "/invoice/list",
                },
                {
                  heading: "For Approval",
                  route: "/policy/approval",
                },
                {
                  heading: "Declined Invoices",
                  route: "/invoice/decline",
                }
              ]
      }
     
    ],
    svgIcon: "media/icons/duotune/general/gen028.svg",
    fontIcon: "bi-layers",
  }
  ,
  {
    pages: [
      {
        sectionTitle: "Policies",
        route: "/policy",
        svgIcon: "media/icons/duotune/general/gen056.svg",
        fontIcon: "bi-archive",
        sub: [
                {
                  heading: "Assigned",
                  route: "/policy/assigned",
                },
                {
                  heading: "Completed",
                  route: "/policy/completed",
                },
                {
                  heading: "Cancellation Request",
                  route: "/policy/cancel-request",
                },
                {
                  heading: "Approve Cancellations & Refunds",
                  route: "/policy/cancel-approval",
                },
                {
                  heading: "Policies Cancelled",
                  route: "/policy/cancelled",
                }
              ]
      }
     
    ],
    svgIcon: "media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers",
  },
  {
    pages: [
      {
        heading: "Users",
        route: "/user/list",
        svgIcon: "media/icons/duotune/general/gen049.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
];

export default DocMenuConfig;
